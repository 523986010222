@use "../abstracts" as *;
@use "../components/card";
@use "../components/splide";

.header{

    &__nav{
        background: #09124240;
        z-index: 2;
        position: absolute;
    }
}
.banner {
    position: relative;
    overflow: hidden;

    canvas {
        @extend %backgroundImagemDefault;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.48;
        z-index: 10;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    &-splide {
        li {
            @include flex(flex, column, center, center);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            @include flex(flex, column, flex-start, center);
            width: 100%;
            height: 100%;
            padding: 1rem 0.5rem;
            transform: translate(-50%, -50%);

            h2 {
                color: white;
                font-family: Rubik;
                font-size: 60px;
                font-weight: 700;
                line-height: 72px;
                letter-spacing: 0em;
                text-align: left;
                width: 500px;
                strong {
                    font-weight: 900;
                }
            }

            p {
                color: white;
                font-family: Rubik;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;

                &:first-of-type:before{
                    content: "";
                    margin-right: 10px;
                    width: 4px;
                    background-color: white;
                    height: 24px;
                    display: inline-block;
                }
                &:last-of-type {
                    margin-bottom: 1.5rem;
                }
            }

            @include for-desktop-up {
                max-width: 90%;
                height: auto;
                background-color: transparent;
                left: 10em;
                transform: translateY(-50%);
            }

            @include for-phone-only{

                h2{
                    font-size: 36px;
                    line-height: 40px;
                    width: 100%;
                }

                p{
                    font-size: 16px;
                    line-height: 20px;
                    width: 100%;
                }
            }
            @include for-tablet-portrait-up {
                p {
                    font-size: size(text, 400);
                }
            }
        }

        &__button {
            min-width: 207px;
            height: 49px;
        }
    }

    .splide {
        &__arrow {
            &--next {
                right: 1rem;
            }

            &--prev {
                left: 1rem;
            }

            svg {
                width: 29px;
                height: 46px;
                fill: white;
            }
        }

        &__pagination {
            bottom: 1.5em;
        }
    }
}

.service{
    background-color: #F4F4F4;
    &__list{
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    &__title{
        width: 100%;
        font-family: Rubik;
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        text-transform: uppercase;
        margin-bottom: 44px;
        color: #1C1F35;
    }

    &__button{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color:white;
        margin-right: auto;
        margin-left: auto;
        background: #091242;
        width: 323px;
        height: 48px;
        padding: 16px;
        margin-top: 36px;

        &:hover{
            border: 1px solid #091242 !important;
            color: #091242 !important;
            background: white !important;
        }
    }

    &__item{
        &-column{

            @include for-phone-only{
                width: 100%;
            }
            width: 407px;
            height: 273px;
            background-color: #091242;
            margin-right: 0px;
            margin-bottom: 24px !important;

            i{
                color:#AB8549;
                font-size: 40px;
                margin: 12px 0px 20px 0px;
            }

            a{
                padding: 28px;
                display: block;
            }
            &:nth-child(even){
                background-color: white;
                i{
                    color:#091242;
                }

                .service__item{

                    &-title{
                        color:#1C1F35;
                    }

                    &-content{
                        p{
                            color:#1C1F35;
                        }
                    }
                }
            }
        }


        &-title{
            font-family: Rubik;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color:white;
            margin-bottom: 13px;
        }

        &-content{
            font-family: Krub;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            width: 276px;
            p{
                color:white;
            }

            &:nth-child(even){
                p{
                    color:1C1F35;
                }
            }
        }

    }
}

.cards{
    @include for-desktop-up {
        padding: 126px 0px 126px 0px !important;
    }

    &__column{

        width: 364px;
        height: 538px;
        margin-left: auto;
        margin-right: auto !important;
        transition: all 0.5s ease-in-out;
        @include for-desktop-up{

            margin-right: 56px;

        }

        @include for-phone-only{
            width: 100%;
        }
        &:last-child{
            margin-right: 0px;
        }

        figure{
            width: 100%;
            height: 426px;
            object-fit: cover;
            margin-bottom: 0px;

            img{
                height: 426px;
                width: 100%;
            }
        }

        &-content{
            background: #091242;
            height: 112px;
            width: 100%;
            padding: 16px 32px 16px 32px;

            h2{
                font-family: Rubik;
                font-size: 32px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                margin-bottom: 3px;
                text-transform: uppercase;
            }

            p{
                font-family: Krub;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
            }
        }

        &:hover{
            scale: 1.05;
        }
    }
}
.about {
    background: #F4F4F4;
    &__title {
        font-family: Rubik;
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        color: #1C1F35;

        &::after {
            margin: 0.2rem 0;
            background: #AB8549;
            height: 3px;
        }
    }
    &__content {
        h3 {
            color: #666C89;
            font-size: 1.75rem;
        }

        p {
            color: #666C89;
            font-family: Krub;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

        }
    }

    &-splide {
        .splide__arrow {
            border-radius: unset;
            border: 1px solid white;
            top: 10%;

            svg {
                fill: white;
            }

            &--next {
                right: 10px;
            }

            &--prev {
                right: 41px;
                left: unset;
            }

            &:hover {
                background-color: white;

                svg {
                    fill: color(primary);
                }
            }
        }
    }
}


// .news {
//     &__title {
//         font-size: 1.75rem;
//         font-weight: bold;

//         &::after {
//             margin: 0.5rem auto;
//         }
//     }

//     &-card {
//         @include grid(grid, 100%, 202px 80px 160px auto, 1rem);

//         &__figure {
//             img {
//                 width: 100%;
//                 height: 202px;
//                 object-fit: cover;
//                 border-radius: 7px;
//             }
//         }

//         &__title {
//             color: color(primary);
//             font-weight: bold;
//             font-size: size(title, 50);
//         }

//         &__text {
//             position: relative;

//             p {
//                 color: color(grayScale, 700);
//                 text-align: justify;
//             }

//             &::after {
//                 content: "";
//                 position: absolute;
//                 bottom: 0;
//                 left: 0;
//                 z-index: 5;
//                 width: 100%;
//                 height: 2px;
//                 background-color: color(primary);
//             }
//         }
//     }
// }

.partners {
    &__title {
        font-family: Rubik;
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: uppercase;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        color: #1C1F35;

        &::after {
            margin-bottom: 6px;
            background: #111C55;
            height: 2px;
            width: 75%;
        }
    }

    &__text{
        font-family: Lato;
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color:#111C55;
        margin-top:15px;
    }

    &__image {
        @extend %transitionDefault;
    }

    &__slide{
        height: 292px;
        width: 359px !important;
        border-radius: 11px;
        background: #FFFFFF;
        box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.18);
        padding: 0px 58px 0px 58px;
    }

    @include for-phone-only{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

.parallax {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 446px;

    &__column {
        background-repeat: no-repeat;
        background-position: 17% 0;
    }

    &__title {
        color: white;
        font-size: size(title, 500);
        font-weight: bold;
    }

    &__text {
        color: white;
    }

    &__link {
        width: 244px;
    }

    @include for-desktop-up {
        background-attachment: fixed;
    }
}
